import UAParser from "ua-parser-js";

/**
 * デバイス判定
 *
 * @param {String: 'pc' or 'sp'} device
 * @return {boolean}
 */
export const mq = (device) => {
  if (window.matchMedia("(max-width:767px)").matches && device === "sp") {
    return true;
  }
  return window.matchMedia("(min-width:768px)").matches && device === "pc";
};

/**
 * ページトップへスクロール - jQuery
 *
 * @param {string} val
 */
export const jqGoToPageTop = (val) => {
  $(val).on("click", () => {
    $("html, body").animate({ scrollTop: 0 }, 500, "swing");
    return false;
  });
};

/**
 * ページトップへスクロール - jQuery
 *
 * @param {string} val
 */
export const jqSmoothScroll = (val) => {
  $(val).on("click", () => {
    const speed = 500;
    const href = $(this).attr("href");
    const target = $(href === "#" || href === "" ? "html" : href);
    const position = target.offset().top;
    $("html, body").animate({ scrollTop: position }, speed, "swing");
    return false;
  });
};

/**
 * アコーディオン処理 - jQuery
 *
 * @param {string} val
 */
export const showAccordion = (val) => {
  $(val).next("*").hide();
  $(val).on("click", (e) => {
    const tag = e.currentTarget;
    if ($(tag).hasClass("show-accordion")) {
      $(tag).removeClass("show-accordion").next("*").slideUp();
    } else {
      $(tag).addClass("show-accordion").next("*").slideDown();
    }
  });
};

/**
 * アコーディオン処理2 - jQuery
 *
 * @param {string} val
 */
export const showAllAccordion = (val) => {
  $(`${val} dt`).on("click", (e) => {
    const tag = e.currentTarget;
    if ($(tag).hasClass("show")) {
      $(tag).removeClass("show").parent("dl").children("dd").slideUp("fast");
    } else {
      // 常に一つだけの処理
      $(tag).addClass("show").parent("dl").children("dd").slideUp("fast");
      $(tag).next("dd").slideDown("fast");

      // 個別に処理する場合（デフォルトコメントアウト）
      // $(tag).addClass('show').next('dd').slideDown('fast');
    }
  });
};

/**
 * タブUI - jQuery
 *
 * @param {string} tabs
 * @param {string} tabContent
 */
export const showTab = (tabs, tabContent) => {
  const tab = $(`${tabs}> *`);
  const tabChild = $(`${tabContent}> *`);
  const url = window.location.href;
  let hash = [];
  hash = url.split("#");

  if (hash[1]) {
    const indexId = tab.index($(`.${hash[1]}`));
    tab.eq(indexId).addClass("show-tab");
    tabChild.hide().eq(indexId).show().addClass("show-tab-child");
  } else {
    tab.eq(0).addClass("show-tab");
    tabChild.hide().eq(0).show().addClass("show-tab-child");
  }
  tab.on("click", (e) => {
    const index = tab.index(e.currentTarget);
    tab.removeClass("show-tab");
    $(this).addClass("show-tab");
    tabChild.hide().removeClass("show-tab-child").eq(index).show().addClass("show-tab-child");
  });
};

/**
 * UAチェック
 *
 * @return {*}
 */
export const uaCheck = () => {
  const ua = UAParser();

  const uaBrowzer = () => {
    if (typeof ua.browser.name !== "undefined") {
      return ua.browser.name.replace(/ /g, "");
    }
    return "browserUnknown";
  };

  const uaDevice = () => {
    if (typeof ua.device.type !== "undefined") {
      return ua.device.type.replace(/ /g, "");
    }
    return "deviceUnknown";
  };

  const uaOS = () => {
    if (typeof ua.os.name !== "undefined") {
      return ua.os.name.replace(/ /g, "");
    }
    return "osUnknown";
  };

  const homeClass = () => {
    let classStr = "";
    classStr += `${uaBrowzer()} `;
    classStr += `${uaDevice()} `;
    classStr += `${uaOS()}`;
    return classStr;
  };

  window.document.addEventListener("DOMContentLoaded", () => {
    window.document.documentElement.className += homeClass();
  });

  return false;
};
