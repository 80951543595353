import Swiper from 'swiper';
import { EffectFade, Autoplay } from 'swiper/modules';
import { uaCheck, mq } from "./modules/utils";
import "paginationjs";

uaCheck();

function jqSmoothScroll() {
  const nav = $('header').outerHeight();

  $('a[href^="#"]').on("click", function () {
    const href = $(this).attr("href");
    const target = $(href === "#" || href === "" ? "html" : href);
    const position = target.offset().top - nav;
    $("html, body").animate({ scrollTop: position }, 500, "swing");
    return false;
  });

	const {hash} = window.location;
	if(hash){
		$("html, body").stop().scrollTop(0);
		setTimeout(()=> {
			const target = $(hash);
			const position = target.offset().top - nav;
			$("html, body").animate({scrollTop:position}, 500, "swing");
		});
	};
}


function suwiperFnc() {
  const swiper = new Swiper('.swiper', {
    modules: [EffectFade, Autoplay],
    loop: true,
    speed: 1500,
    effect: 'fade',
    autoplay: {
      delay: 2000,
    },
  });
}

function accContent() {
  $('.sightseeing-list .title-box').on('click', function() {
    $(this).next().slideToggle();
    $(this).toggleClass('active');
  });
  $('.faq-list dt').on('click', function() {
    $(this).next().slideToggle();
    $(this).parent().toggleClass('active');
  });
}

function headerFnc() {
  if (mq('pc')) {
    let moveTimer = 0;
    let hideTimer = 0;
    const menu = $('.taxiservice-box');
    $('.taxiservice').hover(function () {
      $(this).on('mousemove', () =>{
        // タイマーの解除
        clearTimeout(moveTimer);
        clearTimeout(hideTimer);
        moveTimer = setTimeout(() => {
          $(this).addClass('active');
          menu.slideDown();
        }, 100);
      });
    }, function(){
        clearTimeout(moveTimer);
        hideTimer = setTimeout(() =>{
          $(this).removeClass('active');
          menu.slideUp()
        }, 400);
    });
    const element = document.querySelector('.float-area') // 要素の取得
    window.addEventListener('scroll', () => {
      element.style.left = `${-window.pageXOffset}px`;
    })
  } else {
    $('.togglebtn').on('click', function() {
      $(this).next().slideToggle();
    });
    $('.taxicall-area h3').on('click', function() {
      $(this).next().slideToggle();
    });
  }
}

function includePack() {
  $.ajaxSetup({ cache: false });

  // 現在のURLがテスト環境か本番環境かを判定する
  var basePath = window.location.pathname.includes("/kkg-kobe/") ? "/kkg-kobe/assets/inc/" : "/assets/inc/";

  $("header").load(basePath + "header.html", () => {
    headerFnc();
  });
  $("footer").load(basePath + "footer.html");
  $("#infomation").load(basePath + "infomation.html");
  $("#call").load(basePath + "call.html");
  $("#contact").load(basePath + "contact.html");
  $("#other").load(basePath + "other.html");
  $("#recruit").load(basePath + "recruit.html");
}

function wave1() {
  let unit = 100;
  let canvasList; // キャンバスの配列
  let info = {}; // 全キャンバス共通の描画情報
  let colorList; // 各キャンバスの色情報

  function drawSine(canvas, t, zoom, delay) {
    let xAxis = Math.floor(canvas.height/2);
    let yAxis = 0;
    let context = canvas.contextCache;
    // Set the initial x and y, starting at 0,0 and translating to the origin on
    // the canvas.
    let x = t; // 時間を横の位置とする
    let y = Math.sin(x)/zoom;
    context.moveTo(yAxis, unit*y+xAxis); // スタート位置にパスを置く

    // Loop to draw segments (横幅の分、波を描画)
    for (let i = yAxis; i <= canvas.width + 10; i += 10) {
      x = t+(-yAxis+i)/unit/zoom;
      y = Math.sin(x - delay)/3;
      context.lineTo(i, unit*y+xAxis);
    }
  }

  function drawWave(canvas, color, alpha, zoom, delay) {
		let context = canvas.contextCache;
    context.fillStyle = color; // 塗りの色
    context.globalAlpha = alpha;
    context.beginPath(); // パスの開始
    drawSine(canvas, info.t / 0.8, zoom, delay);
    context.lineTo(canvas.width + 10, canvas.height); // パスをCanvasの右下へ
    context.lineTo(0, canvas.height); // パスをCanvasの左下へ
    context.closePath() // パスを閉じる
    context.fill(); // 波を塗りつぶす
  }

  function draw(canvas, color) {
		// 対象のcanvasのコンテキストを取得
    let context = canvas.contextCache;
    // キャンバスの描画をクリア
    context.clearRect(0, 0, canvas.width, canvas.height);

    // 波を描画 drawWave(canvas, color[数字（波の数を0から数えて指定）], 透過, 波の幅のzoom,波の開始位置の遅れ )
    drawWave(canvas, color[0], 1, 4, 1);
  }

  function update() {
    // eslint-disable-next-line no-restricted-syntax
    for (let canvas of canvasList) {
      // 各キャンバスの描画
      draw(canvas, colorList[canvasList.indexOf(canvas)]);
    }
    // 共通の描画情報の更新
    info.seconds += 0.008;
    info.t = info.seconds * Math.PI;
    // 自身の再起呼び出し
    setTimeout(update, 35);
  }

  function init() {
    info.seconds = 0;
    info.t = 0;
    canvasList = [];
    colorList = [];
    // canvas1個めの色指定
    canvasList.push(document.getElementById("waveCanvas1"));
    colorList.push(['#fff']);
    // 各キャンバスの初期化
    // eslint-disable-next-line no-restricted-syntax
    for (let canvas of canvasList) {
      canvas.width = document.documentElement.clientWidth; // Canvasのwidthをウィンドウの幅に合わせる
      if (mq('pc')) {
        canvas.height = 100; // 波の高さ
      } else {
        canvas.height = 70; // 波の高さ
      }
      canvas.contextCache = canvas.getContext("2d");
    }
    // 共通の更新処理呼び出し
    update();
  }
  init();
}

function wave2() {
  let unit = 100;
  let canvasList; // キャンバスの配列
  let info = {}; // 全キャンバス共通の描画情報
  let colorList; // 各キャンバスの色情報

  function drawSine(canvas, t, zoom, delay) {
    let xAxis = Math.floor(canvas.height/2);
    let yAxis = 0;
    let context = canvas.contextCache;
    let x = t; // 時間を横の位置とする
    let y = Math.sin(x)/zoom;
    context.moveTo(yAxis, unit*y+xAxis); // スタート位置にパスを置く

    // Loop to draw segments (横幅の分、波を描画)
    for (let i = yAxis; i <= canvas.width + 10; i += 10) {
      x = t+(-yAxis+i)/unit/zoom;
      y = Math.sin(x - delay)/3;
      context.lineTo(i, unit*y+xAxis);
    }
  }

  function drawWave(canvas, color, alpha, zoom, delay) {
    let context = canvas.contextCache;
    context.strokeStyle = color; // 線の色
    context.lineWidth = 4; // 線の幅
    context.globalAlpha = alpha;
    context.beginPath(); // パスの開始
    drawSine(canvas, info.t / .7, zoom, delay);
    context.stroke(); // 線
  }

  function draw(canvas, color) {
    // 対象のcanvasのコンテキストを取得
    let context = canvas.contextCache;
    // キャンバスの描画をクリア
    context.clearRect(0, 0, canvas.width, canvas.height);

    // 波線を描画 drawWave(canvas, color[数字（波の数を0から数えて指定）], 透過, 波の幅のzoom,波の開始位置の遅れ )
    drawWave(canvas, color[0], 1, 0.7, 0);
    drawWave(canvas, color[1], 1, 1.4, 0);
    drawWave(canvas, color[2], 0.4, 0.8, 0);
    drawWave(canvas, color[3], 0.4, 1.2, 100);
  }

  function update() {
    // eslint-disable-next-line no-restricted-syntax
    for (let canvasIndex = 0; canvasIndex < canvasList.length; canvasIndex += 1) {
      let canvas = canvasList[canvasIndex];
      // 各キャンバスの描画
      draw(canvas, colorList[canvasIndex]);
    }
    // 共通の描画情報の更新
    info.seconds += 0.005;
    info.t = info.seconds * Math.PI;
    // 自身の再起呼び出し
    setTimeout(update, 35);
  }

  function init() {
    info.seconds = 0;
    info.t = 0;
    canvasList = [];
    colorList = [];

    if( $('#waveCanvas2').length ) {
      canvasList.push(document.getElementById("waveCanvas2"));
      colorList.push(['#fff', '#fff', '#66E3FF', '#66E3FF']);
    };

    if( $('#waveCanvas3').length ) {
      canvasList.push(document.getElementById("waveCanvas3"));
      colorList.push(['#fff', '#fff', '#66E3FF']);
    };

    if( $('#waveCanvas4').length ) {
      canvasList.push(document.getElementById("waveCanvas4"));
      colorList.push(['#fff', '#fff', '#66E3FF']);
    };

    // 各キャンバスの初期化
    // eslint-disable-next-line no-restricted-syntax
    for (let canvasIndex = 0; canvasIndex < canvasList.length; canvasIndex += 1) {
      let canvas = canvasList[canvasIndex];
      canvas.width = document.documentElement.clientWidth; // Canvasのwidthをウィンドウの幅に合わせる
      if (mq('pc')) {
        canvas.height = 90; // 波の高さ
      } else {
        canvas.height = 70; // 波の高さ
      }
      canvas.contextCache = canvas.getContext("2d");
    }
    // 共通の更新処理呼び出し
    update();
  }
  init();
}

function initializePagination() {
  const url = "/assets/data/topics.json" + "?t=" + new Date().getTime(); // キャッシュ防止
  fetch(url)
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((data) => {
      console.log("Fetched data:", data);

      // #topTopicsが存在する場合のみ4件表示
      if ($("#topTopics .topics-list").length) {
        $("#topTopics .topics-list").html(setTopics(data, 4));
      }

      // ページネーションを設定
      setupPagination(data);
    })
    .catch((error) => {
      console.error("Error fetching the topics:", error);
    });
}

function setupPagination(data) {
  // #pagination-containerが存在する場合のみページネーションを設定
  if ($("#pagination-container").length) {
    $("#pagination-container").pagination({
      dataSource: function (done) {
        done(data); // JSONデータを直接渡す
      },
      pageSize: 12, // 表示コンテンツ数
      pageRange: 1, // ページネーションの両側の数
      ellipsisText: "...",

      callback: function (data, pagination) {
        // #data-containerが存在する場合にのみトピックを出力
        if ($("#data-container .topics-list").length) {
          $("#data-container .topics-list").html(setTopics(data));
        }
      },
    });
  }
}

// トピックを表示する関数（limit: 上限数）
function setTopics(topics, limit = null) {
  const commonPath = "/assets/img/topics/"; // 共通のパスを定義
  const limitedTopics = limit ? topics.slice(0, limit) : topics;

  return limitedTopics
    .map(function (topic) {
      // thumbが未入力のときはデフォルト画像を使用
      const imageUrl = topic.thumb ? commonPath + topic.thumb : commonPath + "default.png";

      return (
        "<li><a href='" +
        topic.link +
        "' target='_blank'>" +
        "<div class='thumb'><img src='" +
        imageUrl +
        "'></div>" +
        "<div class='detail-box'>" +
        "<p class='date'>" +
        topic.date +
        "</p>" +
        "<p class='text'>" +
        topic.text +
        "</p>" +
        "</div></a></li>"
      );
    })
    .join(""); // リストアイテムをHTMLに変換して結合
}

window.addEventListener("load", () => {
  jqSmoothScroll();
  suwiperFnc();
  accContent();
  includePack();
  wave1();
  wave2();
  initializePagination();;
});
